import { Container as ContainerDS } from '@benefits/components';
import styled, { css } from 'styled-components';

export const FlexContainer = styled.div<{ sidebar?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  top: ${({ theme }) => theme.component.navBar.root.minHeight};
  min-height: ${({ theme }) => css`calc(100vh - ${theme.component.navBar.root.minHeight})`};

  ${(props) =>
    !props.sidebar &&
    css`
      justify-content: center;
      background-color: #f9f9f9;
    `}
`;

export const Container = styled(ContainerDS)`
  display: flex;
  padding-inline-start: max(79px, env(safe-area-inset-left));

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.breakpointMdMinWidth}) {
    padding-inline-end: max(32px, env(safe-area-inset-right));
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.breakpointLgMinWidth}) {
    padding-inline-end: max(48px, env(safe-area-inset-right));
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.breakpointXlMinWidth}) {
    padding-inline-end: max(64px, env(safe-area-inset-right));
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.breakpointSmMaxWidth}) {
    padding-inline-start: max(24px, env(safe-area-inset-left));
    padding-inline-end: max(24px, env(safe-area-inset-right));
  }
`;
