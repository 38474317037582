import { EventParamsType, EventsDictionary } from '../types';

export enum AddBalanceEventsEnum {
  AddBalanceProductsListView = 'financial:addBalanceProductsList_view',
  AddBalanceProductsListContinueClick = 'financial:addBalanceProductsListContinue_click',
  AddBalancePaymentTypeSelectedClick = 'financial:addBalancePaymentTypeSelected_click',
  AddBalanceDetailsView = 'financial:addBalanceDetails_view',
  AddBalanceDetailsContinueClick = 'financial:addBalanceDetailsContinue_click',
  AddBalanceErrorGeneratingPaymentView = 'financial:errorGeneratingPayment_view',
  AddBalanceGeneratingPaymentView = 'financial:generatingPayment_view',
  AddBalancePaymentGeneratedView = 'financial:addBalancePaymentGenerated_view',
  AddBalancePaymentGeneratedCopyCodeClick = 'financial:addBalancePaymentGeneratedCopyCode_click',
  AddBalancePaymentGeneratedDownloadClick = 'financial:addBalancePaymentGeneratedDownload_click',
  AddBalancePaymentGeneratedInputCopyClick = 'financial:addBalancePaymentGeneratedInputCopy_click',
  AddBalanceCloseBannerClick = 'financial:noShowSinglePaymentBanner_click',
  AddBalanceCloseBannerPermanentlyClick = 'financial:closeSinglePaymentBanner_click',
  AddBalanceAntiFraudPageView = 'financial:anti_fraud_page_view',
  AddBalanceAntiFraudClick = 'financial:anti_fraud_click',
}

export enum AccountsEventsEnum {
  AccountsErrorView = 'financial:homeError_view',
  AccountsEmptyHomeView = 'financial:home_view',
  AccountsEmptyAddBalanceClick = 'financial:addBalanceEmpty_click',
  AccountsHomeView = 'financial:home_view',
  AccountsHomeAddBalanceClick = 'financial:addBalance_click',
  AccountsHomeApplyFilterClick = 'financial:applyFilter_click',
  AccountsHomeApplySwitchClick = 'financial:applySwitch_click',
  AccountsActionsListClick = 'financial:actionsList_click',
  AccountsAddListClick = 'financial:addList_click',
  AccountsBackToLegacyXpClick = 'financial:backToLegacyXp_click',
  AccountsBackToLegacyConfirmationClick = 'financial:backToLegacyXpConfirmationBanner_click',
  PaymentsListOrdinationClick = 'financial:listordenation_click',
  PaymentsProductListHover = 'financial:productList_hover',
  PaymentsErrorGeneratingNfsView = 'financial:errorGeneratingNFs_view',
  PaymentsMultipleNfsView = 'financial:multipleNFs_view',
  PaymentsOpenNfsClick = 'financial:openNFs_click',
  PaymentsBackToFinancialClick = 'financial:backToFinancial_click',
  PaymentsOpenAllNfsClick = 'financial:openAllNFs_click',

  AccountsTransactionListView = 'financial:transaction_history_list_view',
  AccountsTransactionDownloadClick = 'financial:transaction_download_click',
  AccountsTransactionFilterClick = 'financial:transaction_apply_filter_click',
  AccountsTransactionActionsClick = 'financial:transaction_actions_list_click',
  AccountsTransactionErrorView = 'financial:transaction_error_view',
  AccountsTransactionErrorClick = 'financial:transaction_error_click',
  AccountsTransactionEmptyView = 'financial:transaction_empty_view',
  AccountsTransactionErrorRequest = 'financial:transaction_error_request',
  AccountsTransactionDetailView = 'financial:transaction_detail_view',
  AccountsTransactionViewNfsClick = 'financial:transaction_view_nfs_click',
  AccountsTransactionModalReportView = 'financial:transaction_modal_report_view',
}

export type AddBalanceEventsType = typeof AddBalanceEventsEnum;
export type AddBalanceEventType = keyof AddBalanceEventsType;

export const FinancialEvents = { ...AddBalanceEventsEnum, ...AccountsEventsEnum };
export type FinancialEvents = typeof FinancialEvents;

export type FinancialEventsType = typeof FinancialEvents;
export type FinancialEventType = keyof FinancialEventsType;

export type FinancialEventTypeCollection = FinancialEventType[];

const FinancialEventKeys = Object.keys(FinancialEvents) as FinancialEventTypeCollection;

export const financial: EventsDictionary = FinancialEventKeys.reduce((collection, eventKey) => {
  const eventName = FinancialEvents[eventKey];

  return {
    ...collection,
    [eventName]: <FinancialEventParams>(params: EventParamsType<FinancialEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
