import type { EventParamsType, EventsDictionary } from '../types';

export enum CollectiveAgreementEvents {
  COLLECTIVE_AGREEMENT_HOME_VIEW = 'CollectiveAgreements:Home_View',
  COLLECTIVE_AGREEMENT_DIALOG_DELETE_VIEW = 'CollectiveAgreements:Dialog_Delete_View',
  COLLECTIVE_AGREEMENT_REGISTER_VIEW = 'CollectiveAgreements:Register_View',
  COLLECTIVE_AGREEMENT_EDIT_VIEW = 'CollectiveAgreements:Edit_View',
  COLLECTIVE_AGREEMENT_HOME_REGISTER_CLICK = 'CollectiveAgreements:Home_Register_Click',
  COLLECTIVE_AGREEMENT_HOME_ACTION_CLICK = 'CollectiveAgreements:Home_Action_Click',
  COLLECTIVE_AGREEMENT_HOME_EDIT_CLICK = 'CollectiveAgreements:Home_Edit_Click',
  COLLECTIVE_AGREEMENT_HOME_DELETE_CLICK = 'CollectiveAgreements:Home_Delete_Click',
  COLLECTIVE_AGREEMENT_DIALOG_DELETE_CLOSE_CLICK = 'CollectiveAgreements:Dialog_Delete_Close_Click',
  COLLECTIVE_AGREEMENT_DIALOG_DELETE_EDIT_NO_CLICK = 'CollectiveAgreements:Dialog_Delete_Edit_No_Click',
  COLLECTIVE_AGREEMENT_DIALOG_DELETE_EDIT_YES_CLICK = 'CollectiveAgreements:Dialog_Delete_Edit_Yes_Click',
  COLLECTIVE_AGREEMENT_REGISTER_CANCEL_CLICK = 'CollectiveAgreements:Register_Cancel_Click',
  COLLECTIVE_AGREEMENT_REGISTER_NEXT_CLICK = 'CollectiveAgreements:Register_Next_Click',
  COLLECTIVE_AGREEMENT_REGISTER_BACK_CLICK = 'CollectiveAgreements:Register__Back_Click',
  COLLECTIVE_AGREEMENT_EDIT_CANCEL_CLICK = 'CollectiveAgreements:Edit_Cancel_Click',
  COLLECTIVE_AGREEMENT_EDIT_NEXT_CLICK = 'CollectiveAgreements:Edit_Next_Click',
  COLLECTIVE_AGREEMENT_EDIT_EDIT_BACK_CLICK = 'CollectiveAgreements:Edit_Edit_Back_Click',
  COLLECTIVE_AGREEMENT_TOAST_CALLBACK_CLICK = 'CollectiveAgreements:Toast_Callback_Click',
}

export type CollectiveAgreementEventsType = typeof CollectiveAgreementEvents;

export type CollectiveAgreementsEventType = keyof CollectiveAgreementEventsType;

export type CollectiveAgreementsEventTypeCollection = CollectiveAgreementsEventType[];

const NEWS_EVENT_KEYS = Object.keys(CollectiveAgreementEvents) as CollectiveAgreementsEventTypeCollection;

export const CollectiveAgreement: EventsDictionary = NEWS_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = CollectiveAgreementEvents[eventKey];

  return {
    ...collection,
    [eventName]: <CollectiveAgreementEventParams>(params: EventParamsType<CollectiveAgreementEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
