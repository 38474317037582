import { Header, Stepper } from '@benefits/components';
import { LogoIfoodBeneficios, LogoIfoodBeneficiosShort } from '@benefits/crafts';
import React, { PropsWithChildren } from 'react';

import { BaseLayout } from '../../BaseLayout';
import { FlexContainer, SidebarLayout } from './WithStepper.styles';
import type { WithStepperProps } from './WithStepper.type';

export const WithStepper: React.FC<PropsWithChildren<WithStepperProps>> = ({
  children,
  stepperProps,
  noSidebar = false,
  ...baseProps
}) => {
  const Layout = noSidebar ? React.Fragment : SidebarLayout;
  const sidebarContent = stepperProps ? <Stepper {...stepperProps} /> : null;

  return (
    <BaseLayout {...baseProps}>
      <Header
        headerLogo={<LogoIfoodBeneficios.IfoodBeneficiosSVG />}
        headerMobileLogo={<LogoIfoodBeneficiosShort.IfoodBeneficiosShortSVG />}
      />
      <FlexContainer id="WithStepper">
        <Layout hideSidebarOnMobile sidebarContent={sidebarContent}>
          {children}
        </Layout>
      </FlexContainer>
    </BaseLayout>
  );
};
