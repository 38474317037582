export const RECHARGES_NXP = 'RECHARGES_NXP';
export const FINANCIAL_NXP_ENABLED = 'FINANCIAL_NXP_ENABLED';
export const FINANCIAL_NXP_ENABLED_BY_GROUP_ID = 'FINANCIAL_NXP_ENABLED_BY_GROUP_ID';
export const BENF_COMPANIES_PLATFORM_ACL_ENABLED = 'BENF_COMPANIES_PLATFORM_ACL_ENABLED';
export const CARD_PLATFORM_ENABLE_MULTIPLE_ADDRESSES = 'CARD_PLATFORM_ENABLE_MULTIPLE_ADDRESSES';
export const IS_PUBLIC_COMPANY = 'IS_PUBLIC_COMPANY';
export const IS_SUNSHINE_BOT_MFE_ENABLED = 'IS_SUNSHINE_BOT_MFE_ENABLED';
export const MARKETPLACE_EXPERIENCE = 'MARKETPLACE_EXPERIENCE';
export const MARKETPLACE_PUBLIC_COMPANY = 'MARKETPLACE_PUBLIC_COMPANY';
export const INDIVIDUAL_EMPLOYEE_REGISTRATION_NXP = 'INDIVIDUAL_EMPLOYEE_REGISTRATION_NXP';
export const PIX_ON_IFOOD_CARD_DISABLED = 'PIX_ON_IFOOD_CARD_DISABLED';
export const CARDS_NO_NAME_NXP_ENABLED = 'CARDS_NO_NAME_NXP_ENABLED';
export const CARDS_NO_NAME_NXP_ENABLED_LIST = 'CARDS_NO_NAME_NXP_ENABLED_LIST';
export const EMPLOYEE_SPREADSHEET_EXAMPLES_ENABLED = 'EMPLOYEE_SPREADSHEET_EXAMPLES_ENABLED';
export const AUTH_2FA_NXP_ENABLED = 'AUTH_2FA_NXP_ENABLED';
export const PROFILE_2FA_NXP_ENABLED = 'PROFILE_2FA_NXP_ENABLED';
export const COLAB_PLUS_CONTRACTED = 'COLAB_PLUS_CONTRACTED';
export const CHANGE_LICENSE_NAME = 'CHANGE_LICENSE_NAME';
export const WITH_CREDENTIALS = 'WITH_CREDENTIALS';
export const CHANGE_FANTASY_SOCIAL_NAME = 'CHANGE_FANTASY_SOCIAL_NAME';
export const ENABLE_USER_EMAIL_AS_ACCOUNT_ID = 'ENABLE_USER_EMAIL_AS_ACCOUNT_ID';
export const DISABLE_CHARGEBACK_IN_RECHARGES = 'DISABLE_CHARGEBACK_IN_RECHARGES';
export const FRAUD_BLOCK_COMPANIES_ENABLED = 'FRAUD_BLOCK_COMPANIES_ENABLED';
export const NATAL_SALDO_LIVRE_BANNER_ENABLED = 'NATAL_SALDO_LIVRE_BANNER_ENABLED';
