import type { EventParamsType, EventsDictionary } from '../types';

export enum EmployeeEvents {
  EmployeeHomeView = 'employee:employeeHome_view',
  EmployeeHomeListView = 'employee:employeeHome_callback',
  DraftHomeView = 'employee:draftHome_view',
  DraftHomeClickItem = 'employee:draftHome_click',
  EmployeeRegisterTypeView = 'employee:employeeInsert_view',
  EmployeeRegisterTypeClick = 'employee:employeeInsert_option_click',
  EmployeeSpreadsheetCallback = 'employee:employeeSpreadSheet_callback',
  EmployeeSpreadSheetUploadView = 'employee:employeeSpreadSheet_upload_view',
  EmployeeSpreadSheetUploadClick = 'employee:employeeSpreadSheet_upload_click',
  EmployeeSpreadSheetUploadInvalidClick = 'employee:employeeSpreadSheet_invalid_upload_click',
  EmployeeSpreadSheetWithErrorCallback = 'employee:employeeSpreadSheetWithError_callback',
  FormatSpreadSheetErrorView = 'employee:formatSpreadSheetError_view',
  SpreadSheetDataInfoView = 'employee:spreadsheetDataInfo_view',
  SpreadSheetDataInfoClick = 'employee:spreadsheetDataInfo_click',
  RemoveEmployeeWithErrorClick = 'employee:removeEmployeeWithError_click',
  MappingColumnsView = 'employee:mappingColumns_view',
  MappgingColumnsClick = 'employee:mappingColumns_click',
  MappingColumnsErrors = 'employee:mappingcolumns_errors',
  MappingColumnsCallback = 'employee:mappingColumns_callback',
  MappingColumnsLoadView = 'employee:mappingColumnsLoad_view',
  MappingColumnsLoadClick = 'employee:mappingColumnsLoad_click',
  ProcessingDraftLoadView = 'employee:processingDraftLoad_view',
  ConfirmingDrafDataView = 'employee:confirmingDraftData_view',
  ConfirmingDrafDataErrors = 'employee:confirmingDraftData_errors',
  ConfirmingDrafDataClick = 'employee:confirmingDraftData_click',
  ConfirmingDrafDataCallback = 'employee:confirmingDraftData_callback',
  ConfirmingDraftDataLoadView = 'employee:confirmingDraftDataLoad_view',
  ConfirmingDraftDataLoadClick = 'employee:confirmingDraftDataLoad_click',
  ConfirmingDraftDataLoadCallback = 'employee:confirmingDraftDataLoad_callback',
  ProcessedDraftLoadView = 'employee:processedDraftLoad_view',
  ProcessedDraftDataLoadClick = 'employee:processedDraftDataLoad_click',
  ProcessedDraftDataLoadCallbak = 'employee:processedDraftDataLoad_callback',
  EmployeeSpreadSheetRegisteredWithSuccessCallback = 'employee:employeeSpreadSheetRegisteredWithSuccess_callback',
  EmployeeSpreadSheetRegisteredWithErrorCallback = 'employee:employeeSpreadSheetRegisteredWithError_callback',
  EmployeeManuallyRegisterView = 'employee:employeeManuallyRegister_view',
  EmployeeManuallyRegisteredSuccess = 'employee:employeeManuallyRegisteredWithSuccess_callback',
  EmployeeManuallyRegisteredError = 'employee:employeeManuallyRegisteredWithError_callback',
  EmployeeManuallyRegisterCollectiveAgreementClick = 'employee:employeeManuallyRegisterCollectiveAgreement_click',
  EmployeeManuallyRegisterCollectiveAgreementNextClick = 'employee:employeeManuallyRegisterCollectiveAgreementNext_click',
  EmployeeManuallyRegisterCollectiveAgreementCancelClick = 'employee:employeeManuallyRegisterCollectiveAgreementCancel_click',
  EmployeeManuallyRegisterCollectiveAgreementCloseClick = 'employee:employeeManuallyRegisterCollectiveAgreementClose_click',
  EmployeeManuallyRegisterCollectiveAgreementSuccessCallback = 'employee:employeeManuallyRegisterCollectiveAgreementSuccess_callback',
  EmployeeManuallyRegisterCollectiveAgreementErrorCallback = 'employee:employeeManuallyRegisterCollectiveAgreementError_callback',
  TutorialClick = 'employee:collapseCommunication_click',
  TutorialModalClick = 'employee:modalCommunication_click',
  NotAllowedDraftView = 'employee:notAllowedDraft_view',
  NotAllowedDraftClick = 'employee:notAllowedDraft_click',
  AntiFraudAntiFraudView = 'AntiFraud:AntiFraudView',
  AntiFraudAntiFraudBackClick = 'AntiFraud:AntiFraudBackClick',
  AntiFraudAntiFraudBackHomeClick = 'AntiFraud:AntiFraudBackHomeClick',
  AntiFraudAntiFraudChatClick = 'AntiFraud:AntiFraudChatClick',
  AntiFraudAntiFraudBannerChatClick = 'AntiFraud:AntiFraudBannerChatClick',
  AntiFraudAntiFraudBannerColabsClick = 'AntiFraud:AntiFraudBannerColabsClick',
  EmployeeCardlessDrawerView = 'employee:CardlessDrawerView',
  EmployeeCardlessDrawerCloseClick = 'employee:CardlessDrawerCloseClick',
  EmployeeCardlessDrawerCancelClick = 'employee:CardlessDrawerCancelClick',
  EmployeeCardlessDrawerDownloadClick = 'employee:CardlessDrawerDownloadClick',
}

export type EmployeeEventsType = typeof EmployeeEvents;

export type EmployeeEventType = keyof EmployeeEventsType;

export type EmployeeEventTypeCollection = EmployeeEventType[];

const EMPLOYEE_EVENT_KEYS = Object.keys(EmployeeEvents) as EmployeeEventTypeCollection;

export const employee: EventsDictionary = EMPLOYEE_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = EmployeeEvents[eventKey];

  return {
    ...collection,
    [eventName]: <EmployeeEventParams>(params: EventParamsType<EmployeeEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
