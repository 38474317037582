import { EventParamsType, EventsDictionary } from '../types';

export enum AuthenticationEvents {
  AccessLoginView = 'access:login_view',
  AccessLoginErrorCallback = 'access:login_error_callback',
  AccessLoginErrorPasswordCallback = 'access:login_error_password_callback',
  AccessLoginErrorUnexpectedCallback = 'access:login_error_unexpected_callback',
  AccessLoginErrorUnavailableSystemCallback = 'access:login_error_unavailableSystem_callback',
  AccessLoginErrorAccessDeniedCallback = 'access:login_error_accessDenied_callback',
  AccessLoginForgotPasswordClick = 'access:login_forgotPassword_click',
  AccessLoginAccountLoginClick = 'access:login_accountLogin_click',
  AccessLoginSsoLoginClick = 'access:login_ssoLogin_click',
  AccessRequestNewPasswordView = 'access:requestNewPassword_view',
  AccessRequestNewPasswordSuccessView = 'access:requestNewPassword_success_view',
  AccessRequestNewPasswordReturnLoginClick = 'access:requestNewPassword_returnLogin_click',
  AccessRequestNewPasswordSendAccessEmailClick = 'access:requestNewPassword_sendAccessEmail_click',
  AccessRequestNewPasswordSuccessReturnLoginClick = 'access:requestNewPassword_success_returnLogin_click',
  AccessCreatePasswordView = 'access:createPassword_view',
  AccessCreatePasswordSuccessView = 'access:createPassword_success_view',
  AccessCreatePasswordExpiredView = 'access:createPassword_expired_view',
  AccessCreatePasswordExpiredReturnLoginClick = 'access:createPassword_expired_returnLogin_click',
  AccessCreatePasswordExpiredSendAccessEmailClick = 'access:createPassword_expired_sendAccessEmail_click',
  AccessCreateNewPasswordReturnLoginClick = 'access:createNewPassword_returnLogin_click',
  AccessCreateNewPasswordCreatePasswordClick = 'access:createNewPassword_createPassword_click',
  AccessCreateNewPasswordSuccessClick = 'access:createNewPassword_success_click',
  Access2FAChoiceView = 'access:2FAChoiceView',
  Access2FAChoiceClick = 'access:2FAChoiceClick',
  Access2FALaterClick = 'access:2FALaterClick',
  Access2FAChoiceErrorView = 'access:2FAChoiceErrorView',
  Access2FAChoiceRetryClick = 'access:2FAChoiceRetryClick',
  Access2FADownloadAmppView = 'access:2FADownloadAppView',
  Access2FADownloadAppBackClick = 'access:2FADownloadAppBackClick',
  Access2FADownloadAppHelpClick = 'access:2FADownloadAppHelpClick',
  Access2FADownloadAppClick = 'access:2FADownloadAppClick',
  Access2FADownloadAppPlayStoreClick = 'access:2FADownloadAppPlayStoreClick',
  Access2FADownloadAppAppStoreClick = 'access:2FADownloadAppAppStoreClick',
  Access2FARegisterAppView = 'access:2FARegisterAppView',
  Access2FARegisterAppBackClick = 'access:2FARegisterAppBackClick',
  Access2FARegisterAppHelpClick = 'access:2FARegisterAppHelpClick',
  Access2FARegisterAppClick = 'access:2FARegisterAppClick',
  Access2FARegisterPhoneView = 'access:2FARegisterPhoneView',
  Access2FARegisterEmailView = 'access:2FARegisterEmailView',
  Access2FARegisterPhoneBackClick = 'access:2FARegisterPhoneBackClick',
  Access2FARegisterEmailBackClick = 'access:2FARegisterEmailBackClick',
  Access2FARegisterPhoneClick = 'access:2FARegisterPhoneClick',
  Access2FARegisterEmailClick = 'access:2FARegisterEmailClick',
  Access2FACodeView = 'access:2FACodeView',
  Access2FACodeBackClick = 'access:2FACodeBackClick',
  Access2FACodeHelpClick = 'access:2FACodeHelpClick',
  Access2FACodeClick = 'access:2FACodeClick',
  Access2FAErrorView = 'access:2FAErrorView',
  Access2FAErrorCloseClick = 'access:2FAErrorCloseClick',
  Access2FASuccessView = 'access:2FASuccessView',
  Access2FASuccessClick = 'access:2FASuccessClick',
}

export type AuthenticationEventsType = typeof AuthenticationEvents;

export type AuthenticationEventType = keyof AuthenticationEventsType;

export type AuthenticationEventTypeCollection = AuthenticationEventType[];

const AuthenticationEventKeys = Object.keys(AuthenticationEvents) as AuthenticationEventTypeCollection;

export const authentication: EventsDictionary = AuthenticationEventKeys.reduce((collection, eventKey) => {
  const eventName = AuthenticationEvents[eventKey];

  return {
    ...collection,
    [eventName]: <AuthenticationEventParams>(params: EventParamsType<AuthenticationEventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
