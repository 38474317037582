import type { EventParamsType, EventsDictionary } from '../types';

export enum ReportEvents {
  CARDS_VIEW = 'reports:cardsReport_view',
  SELECT_COMPANY_CLICK = 'reports:cardsReport_selectCompanyID_click',
  SelectCompanyClick = 'reports:cardsReport_selectCompanyID_click',
  ReportPageView = 'reports:accessReportsModule_view',
  RechargesPageView = 'reports:rechargesReport_view',
  RechargeByRechargeView = 'reports:reportsByRecharge_view',
  RechargeByEmployeeView = 'reports:reportByEmployee_view',
  CardPageView = 'reports:cardsReport_view',
  GeneratingReportView = 'reports:insideRechargesGeneratingReport_view',
  GeneratingRechargeEmployeeReportView = 'reports:reportByEmployeeGenerating_view',
  DownloadReportView = 'reports:insideRechargesReportGenerated_view',
  DownloadRechargeEmployeeReportView = 'reports:reportByEmployeeGenerated_view',
  DownloadRechargeGroupingReportView = 'reports:reportsInBatchGenerated_view',
  GeneratingReportStillWaiting = 'reports:insideRechargesGeneratingReportStillWaiting_view',
  GenerateReportError = 'reports:RechargeReportErrorToGenerate_view',
  RechargeReportTypeClick = 'reports:rechargesReportAccess_click',
  CompanieSelectClick = 'reports:rechargesReportCNPJChanged_click',
  EmployeeSelectClick = 'reports:reportByEmployeeFiltered_view',
  EmployeeFilterBenefitClick = 'reports:byEmployeeFilterApplied_click',
  GenerateReportButtonClick = 'reports:insideRechargesGenerateReport_click',
  GenerateRechargeEmployeeReportButtonClick = 'reports:reportByEmployee_click',
  DownloadReportButtonClick = 'reports:insideRechargesDownloadReport_click',
  DownloadRechargeEmployeeButtonClick = 'reports:reportByEmployeeDownload_click',
  BackToRechargeListButtonClick = 'reports:insideRechargesDownloadBackToList_click',
  RetryGenerateReportButtonClick = 'reports:rechargeGeneratedErrorTryAgain_click',
  CancelGenerateReportButtonClick = 'reports:rechargeGeneratedErrorCancelAndReturn_click',

  GroupedPageView = 'reports:reportsInBatch_view',
  GroupedPageWithTableView = 'reports:reportsInBatchTableLoaded_view',
  GroupedFilterButtonClick = 'reports:reportsInBatchFilterApplied_click',
  GroupedSelectCompanyClick = 'reports:cardsReport_selectCompanyID_click',
  GroupedGenerateReportButtonClick = 'reports:reportsInBatchGenerateReports_click',
  GroupedDownloadReportButtonClick = 'reports:reportsInBatchDownload_click',

  ReportsTabChanged = 'reports:tabChange',
  ReportsTabClosed = 'reports:tabClose:click',
  ReportsModuleChanged = 'reports:moduleChange_click',
  GeneratingReportGifWaiting = 'reports:gifScreenLoading_view',

  // new reports events
  ReportsHomePageView = 'reports:home_page_view',
  ReportsEmployeeDrawerView = 'reports:employee_drawer_view',
  ReportsRechargeDrawerView = 'reports:recharge_drawer_view',
  ReportsRechargeByEmployeeDrawerView = 'reports:recharge_by_employee_drawer_view',
  ReportsRechargeByPeriodDrawerView = 'reports:recharge_by_period_drawer_view',
  ReportsRechargeByRechargeFilterDrawerView = 'reports:recharge_by_recharge_filter_drawer_view',
  ReportsRechargeByRechargeGroupingDrawerView = 'reports:recharge_by_recharge_grouping_drawer_view',
  ReportsFinancialStatementDrawerView = 'reports:financial_statement_drawer_view',
  ReportsProcessingReportDrawerView = 'reports:processing_report_drawer_view',
  ReportsHomeReportTypeClick = 'reports:home_report_type_click',
  ReportsEmployeeDrawerClick = 'reports:employee_drawer_click',
  ReportsRechargeDrawerClick = 'reports:recharge_drawer_click',
  ReportsRechargeByEmployeeDrawerClick = 'reports:recharge_by_employee_drawer_click',
  ReportsRechargeByPeriodDrawerClick = 'reports:recharge_by_period_drawer_click',
  ReportsRechargeByRechargeDrawerClick = 'reports:recharge_by_recharge_drawer_click',
  ReportsFinancialStatementDrawerClick = 'reports:financial_statement_drawer_click',
  ReportsFromRechargeDownloadClick = 'reports:report_from_download_click',
  ReportsCardsDrawerView = 'reports:cards_drawer_view',
  ReportsCardsDrawerClick = 'reports:cards_drawer_click',
}

export type ReportEventsType = typeof ReportEvents;

export type ReportEventType = keyof ReportEventsType;

export type ReportEventTypeCollection = ReportEventType[];

const REPORT_EVENT_KEYS = Object.keys(ReportEvents) as ReportEventTypeCollection;

export const report: EventsDictionary = REPORT_EVENT_KEYS.reduce((collection, eventKey) => {
  const eventName = ReportEvents[eventKey];

  return {
    ...collection,
    [eventName]: <EventParams>(params: EventParamsType<EventParams>) => ({
      name: eventName,
      label: eventName,
      revision: 0,
      metadata: params,
    }),
  };
}, {});
